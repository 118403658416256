<template>
  <div style="width: 90% !important" class="mx-auto">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <v-row class="my-2">
      <v-col cols="8"></v-col>
      <v-col cols="4">
        <div class="d-flex flex-row justify-end align-center">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
          <!-- <v-select
          v-model="selectedColumns"
          :items="headerOptions"
          item-text="text"
          item-value="value"
          label="Select Columns"
          multiple
          outlined
          hide-details
          dense
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item.text }},</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption"
              >(+{{ visibleHeaders.length - 2 }} others)</span
            >
          </template>
        </v-select> -->

          <v-menu offset-y :close-on-content-click="false" v-model="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                min-width="41"
                class="settings-btn px-0 elevation-0 justify-end"
                text
                v-bind="attrs"
                v-on="on"
              >
                <TransactionSettings />
              </v-btn>
            </template>
            <v-container class="settings">
              <v-sheet
                class="f-inter fs-14 fw-600 mt-2 mb-3"
                style="color: var(--v-lightPurple-base)"
                >Default</v-sheet
              >
              <v-row no-gutters>
                <v-col
                  cols="6"
                  v-for="(item, index) in headers"
                  v-show="item.visible"
                  :key="index"
                >
                  <v-checkbox
                    class="mt-0 mb-3"
                    :label="item.text"
                    hide-details
                    v-model="columnsToAdd"
                    :value="item.value"
                    :ripple="false"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider class="border-opacity-25"></v-divider>
              <v-sheet
                class="f-inter fs-14 fw-600 mt-2 mb-3"
                style="color: var(--v-lightPurple-base)"
                >More Columns</v-sheet
              >
              <v-row no-gutters>
                <v-col
                  cols="6"
                  v-for="(item, index) in headers"
                  v-show="!item.visible"
                  :key="index"
                >
                  <v-checkbox
                    class="mt-0 mb-3"
                    :label="item.text"
                    hide-details
                    v-model="columnsToAdd"
                    :value="item.value"
                    :ripple="false"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <div class="d-flex flex-row-reverse mt-3">
                <v-btn class="custom-btn ml-3" @click="addColumns">
                  <span class="fs-12 fw-500 f-inter text-none lightPurple--text"
                    >Save</span
                  >
                </v-btn>
                <v-btn
                  text-transform="none"
                  class="custom-btn ml-3"
                  @click="resetCheckbox"
                  ><span class="fs-12 fw-500 f-inter text-none subHeading--text"
                    >Cancel</span
                  ></v-btn
                >
              </div>
            </v-container>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- <v-container class="listing"> -->
    <Loader v-if="loader" />
    <v-data-table
      hide-default-header
      :items="accounts"
      :search="search"
      :itemsPerPage="pageItems"
      :hide-default-footer="true"
      height="67.8vh"
      fixed-header
      class="text-no-wrap table-border"
    >
      <!-- <template slot="header">
          <thead>
            <tr>
              <th
                class="lightPurple"
                v-for="(header, headerKey) in visibleHeaders"
                :key="headerKey"
              >
                <span class="white--text"> {{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template> -->

      <!-- <template
        v-for="(header, index) in visibleHeaders"
        v-slot:[`header.${header.value}`]="{ header }"
      >
        <th class="fs-14 fw-700 f-inter totalBlack--text" :key="index">
          {{ header.text }}
        </th>
      </template> -->

      <template slot="header">
        <thead>
          <tr>
            <th
              v-for="(header, headerKey) in visibleHeaders"
              :key="headerKey + 'A'"
              class="bottom-tr-td"
            >
              <span class="fs-14 fw-700 f-inter"> {{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>

      <!-- class="mb-5 pb-5" -->
      <template v-slot:body="{ items }">
        <template v-for="item in items">
          <tr class="fs-12 fw-400 f-inter">
            <td
              v-for="(headers, index) in visibleHeaders"
              :key="index + 'A'"
              class="pl-1 bottom-tr-td pt-1 pb-1"
            >
              <v-col>
                <span
                  v-if="headers.value === 'updatedDateUTC'"
                  class="textPrimary--text"
                >
                  {{ item[updatedDateUTC] | formatISODate }}
                </span>
                <span v-else>
                  {{ item[headers.value] }}
                </span>
              </v-col>
            </td>
          </tr>
        </template>
      </template>

      <!-- <template v-slot:[`item.updatedDateUTC`]="{ item }">
        <span class="fs-12 fw-400 f-inter textPrimary--text">
          {{ item.updatedDateUTC | formatISODate }}
        </span>
      </template> -->
    </v-data-table>
    <!-- </v-container> -->
    <div class="mt-4">
      <pagination
        class="pa-0"
        :itemsPerPage="pageItems"
        :page="paginate.page"
        :pageCount="paginate.lastPage"
        :totalCount="paginate.total"
        @next-page-change="fetchAccountsFromDB($event)"
        @prev-page-change="fetchAccountsFromDB($event)"
        @page-number-changed="fetchAccountsFromDB($event)"
        @items-per-page-change="fetchAccountsFromDB($event)"
      ></pagination>
    </div>

    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api";
import Pagination from "../../common/Pagination.vue";
import TransactionSettings from "../../../assets/svg-icons/transaction-page-setings.vue";
import Loader from "../../common/xero-form/Loader.vue";
// import NoSoftwareConnected from "../../common/connect-software/NoSoftwareConnected.vue";
export default {
  components: {
    Pagination,
    TransactionSettings,
    Loader,
    // NoSoftwareConnected,
  },
  data() {
    return {
      menu: false,
      search: "",
      loader: false,
      accounts: [],
      columnsToAdd: [],
      headers: [
        {
          text: "Name",
          value: "name",
          width: "550px",
          visible: true,
          showInTable: true,
        },
        { text: "Type", value: "type", visible: true, showInTable: true },
        {
          text: "Reporting Code Name",
          value: "reportingCodeName",
          visible: true,
          showInTable: true,
        },
        { text: "Code", value: "code", visible: false, showInTable: false },
        {
          text: "Bank Account Number",
          value: "bankAccountNumber",
          visible: false,
          showInTable: false,
        },
        { text: "Status", value: "status", visible: false, showInTable: false },
        {
          text: "Description",
          value: "description",
          visible: false,
          showInTable: false,
        },
        {
          text: "Bank Account Type",
          value: "bankAccountType",
          visible: false,
          showInTable: false,
        },
        {
          text: "Currency Code",
          value: "currencyCode",
          visible: false,
          showInTable: false,
        },
        {
          text: "Tax Type",
          value: "taxType",
          visible: false,
          showInTable: false,
        },
        {
          text: "Enable Payments To Account",
          value: "enablePaymentsToAccount",
          visible: false,
          showInTable: false,
        },
        {
          text: "Show InExpense Claims",
          value: "showInExpenseClaims",
          visible: false,
          showInTable: false,
        },
        { text: "Class", value: "_class", visible: false },
        {
          text: "System Account",
          value: "systemAccount",
          visible: false,
          showInTable: false,
        },
        {
          text: "Reporting Code",
          value: "reportingCode",
          visible: false,
          showInTable: false,
        },
        {
          text: "Has Attachments",
          value: "hasAttachments",
          visible: false,
          showInTable: false,
        },
        {
          text: "Updated DateUTC",
          value: "updatedDateUTC",
          visible: false,
          showInTable: false,
        },
        {
          text: "Add To Watchlist",
          value: "addToWatchlist",
          visible: false,
          showInTable: false,
        },
      ],
      selectedColumns: ["name", "type", "reportingCodeName"],
    };
  },
  mounted() {
    this.fetchAccountsFromDB();
    console.log("visible headers", this.visibleHeaders);
    console.log("header options", this.headerOptions);
    this.columnsToAdd = this.selectedColumns;
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      paginate: "ticket/getPaginate",
      pageItems: "ticket/getPageItems",
    }),
    headerOptions() {
      return this.headers.map((header) => ({
        text: header.text,
        value: header.value,
      }));
    },
    visibleHeaders() {
      return this.headers.filter((header) =>
        this.selectedColumns.includes(header.value)
      );
    },
  },
  methods: {
    addColumns() {
      this.selectedColumns = this.columnsToAdd;
      this.menu = false;
    },
    resetCheckbox() {
      this.selectedColumns = ["name", "type", "reportingCodeName"];
      this.menu = false;
    },
    // Method to fetch chart of accounts from database
    fetchAccountsFromDB(pgNo) {
      if (pgNo === undefined || pgNo === null) pgNo = 1;
      this.loader = true;
      ApiService.GetRequest(
        `report/accounts/${this.currentWorkspace.workspaceId}`,
        {
          page: pgNo,
          size: this.pageItems,
        }
      )
        .then((res) => {
          console.log(res.data, "Accounts");
          console.log(res.data.count, pgNo, this.pageItems, "---");

          this.loader = false;
          this.$store.commit("ticket/setPaginate", {
            itemsPerPage: this.pageItems,
            page: pgNo,
            lastPage: res.data.count < 10 ? 1 : Math.ceil(res.data.count / 10),
            total: res.data.count,
          });
          this.accounts = res.data.accounts;
          console.log("Accounts data", this.accounts);
        })
        .catch((err) => {
          console.log(err.response);
          this.loader = false;
        });
    },
  },
};
</script>

<!-- height: 520px; -->
<!-- .table-position {
  position: relative;
  top: 15px;
} -->
<style scoped>
:deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: var(--v-totalBlack-base) !important;
}
:deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--v-totalBlack-base) !important;
}
.table-border {
  border-left: 1px solid var(--v-lightBackground-base);
  border-right: 1px solid var(--v-lightBackground-base);
  border-bottom: 1px solid var(--v-lightBackground-base);
  border-radius: 0 !important;
}
.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
.settings-btn:hover {
  color: white !important;
}

.settings {
  width: 410px;
  height: 100%;
  background-color: white;
}
.bottom-tr-td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  /* margin-bottom: 15px; */
}

:deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}
</style>
